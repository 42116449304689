<template>
  <div class="characteristic">
    <jet-card title="产品特性" :titleType="2">
      <ul class="card-content">
        <li>
          <a-row justify="space-between">
            <a-col :span="6">
              <img src="@/assets/images/production/edge/icon1.png">
            </a-col>
            <a-col :span="18">
              <div>
                <h4 class="card-title">兼容性</h4>
                <p>JetLinks Edge 网关可以跨硬件平台、跨操作系统使用，以降低程序与硬件的耦合度。</p>
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row justify="space-between">
            <a-col :span="6">
              <img src="@/assets/images/production/edge/icon2.png" alt="">
            </a-col>
            <a-col :span="18">
              <div>
                <h4 class="card-title">特异性</h4>
                <p>
                  对特定的硬件进行开发，以提高可用性和用户体验。Jetlinks官方将推出搭载网关应用的硬件设备，针对性的优化部分功能。
                </p>
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row justify="space-between">
            <a-col :span="6">
              <img src="@/assets/images/production/edge/icon3.png" alt="">
            </a-col>
            <a-col :span="18">
              <div>
                <h4 class="card-title">标准与客制</h4>
                <p>
                  内置多种设备接入的标准协议，同时内置一些行业内主流厂商的接入协议。
                </p>
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row justify="space-between">
            <a-col :span="6">
              <img src="@/assets/images/production/edge/icon2.png" alt="">
            </a-col>
            <a-col :span="18">
              <div>
                <h4 class="card-title">开放源码</h4>
                <p>
                  和JetLinks平台一样，一如既往。JetLinks Edge 网关仍然开放源代码。
                </p>
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row justify="space-between">
            <a-col :span="6">
              <img src="@/assets/images/production/edge/icon3.png" alt="">
            </a-col>
            <a-col :span="18">
              <div>
                <h4 class="card-title">可拓展</h4>
                <p>
                  JetLinks Edge 网关软件部分采用高拓展架构。取得源代码后，用户可以自行拓展加入新功能。
                </p>
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row justify="space-between">
            <a-col :span="6">
              <img src="@/assets/images/production/edge/icon4.png" alt="">
            </a-col>
            <a-col :span="18">
              <div>
                <h4 class="card-title">定制化</h4>
                <p>
                  可与客户共同进行软硬件开发，依照需求定制化。
                </p>
              </div>
            </a-col>
          </a-row>
        </li>
      </ul>
      <img src="@/assets/images/production/edge/product.png" alt="">
    </jet-card>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {

    }
  },
  components: {

  },
  methods: {

  },
}
</script>
<style scoped lang="less">
.card-content {
  display: flex;

  p {
    margin-top: 1rem;
    color: rgba(11, 17, 69, 0.8);;
    font-size: 1rem;
  }

  li {
    width: 31%;
    height: 12.5rem;

    img {
      width: 5.926vh;
    }
  }

  li:nth-child(odd) {
    margin-right: 0;
  }
}
</style>