<template>
  <jet-card class="cor-function" title="核心功能" :titleType="2" bgColor="#F5F8FE">
    <ul class="function">
      <li>
        <h4 class="card-title">
          视频接入
        </h4>
        <p>
          在边缘网关上配置摄像头、NVR等视频源连接，获取到视频源地址后，推送到云平台上。云平台运行流媒体服务，对视频流处理后，提供给业务平台调用。目前计划支持的视频源接入协议:ONVIF、GB28281海康协议、大华协议。边缘网关还能接收从平台下方的识别对比库视频源上报图片后，根据AI算法，对图片和对比库进行对比，对比的结果数据上传到上级平台。
        </p>
        <img src="@/assets/images/production/edge/process.png" alt="" class="process">
      </li>
      <li>
        <h4 class="card-title">
          物联网接入
        </h4>
        <p>项目现场的物联网设备和边缘网关处干同一局域网中，物联网设备直接边缘网关。支持连接协议MQTT.
          COAP、HTTP、TCP、UDP等，同时支持工业设备使用485等串口直接连接边缘网关。接收到物联网设备
          的报文后，在边缘网关进行编解码，转为结构化数据，上传到上级平台;云平台下发指令到边缘网关，边
          缘网关进行编解码后将对应指令下发到设备，设备完成操作。边缘网关中的结构化数据可进行规则计算

          并接收云平台下发的规则实例。云平台可将协议解析包下放到边缘网关。边缘网关可以将设备上报的数据直接转发到云平台，在云端进行报文解析。

        </p>
        <img src="@/assets/images/production/edge/process.png" alt="" class="process">
      </li>
      <li>
        <h4 class="card-title">
          网关管理
        </h4>
        <p>为应对复杂的网络环境，JetLinks Edge网关标配两个网络接口，可以对网口进行手动、自动网络配置。方便用户使用内外网环境。可通过双网口访问和传输内外网数据，提高网络传输效率。
        </p>
      </li>
      <li>
        <h4 class="card-title">
          平台连接
        </h4>
        <p>网关内置连接到JetLinks物联网平台。可上报网关的所有数据和平台进行联动；平台也可控制接入网关的所有设备。
          网关能配置数据持久化。与平台断开连接后，将数据保存在网关中，恢复连接后，将本地存储的数据上报到上级平台。
        </p>
      </li>
      <li>
        <h4 class="card-title">
          规则引擎
        </h4>
        <p>规则引擎是一种根据规则中包含的指定过滤条件，判断其能否匹配运行时刻的实时条件来执行规则中所规定的动作的引擎。是网关提供的可视化,流程化的设备间数据(逻辑)处理工具，也可用于网关消息的转发与接收。
        </p>
      </li>
      <li>
        <h4 class="card-title">
          设备告警
        </h4>
        <p>网关提供一套可视化的基于规则引擎设置的告警规则，根据用户的需求进行自主配置，用户可通过填写相应内容，配置相应规则和内容，即可使得网关在满足设置条件时推送到管理后台，并促发自定义规则，联动设备进行不同动作。
        </p>
      </li>
    </ul>
  </jet-card>
</template>

<script>
export default {
  name: '',
  data() {
    return {

    }
  },
  components: {

  },
  methods: {

  },
}
</script>
<style scoped lang="less">
.function {
  img {
    position: absolute;
    bottom: 20px;
  }

  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;

  .card-title {
    margin-left: -0.2rem;
    margin-bottom: 1rem;
    color: #0E6EFF;
  }
  .process{
    width: 25.417vw;
    margin-top: 4rem;
  }
  li:nth-child(odd) {
    margin-right: 2.5rem;
  }

  li:nth-child(-n+2) {
    height: 53vh;
    min-height: 390px;
  }

  li {
    padding: 1rem 3rem 2rem 3rem;
    width: 35.75rem;
    border: #EFF3F6 solid 1px;
    position: relative;
    background-color: #FFFFFF;

    p{
      color: #0B1145;
    }
  }


}
</style>