<template>
  <div class="jetlinks-iot">
    <Banner title="JetLinks EDGE" subTitle="物联网边缘网关" :label="label">
      <img src="@/assets/images/production/edge.png">
    </Banner>
    <Characteristic></Characteristic>
    <CoreFunction></CoreFunction>
    <HardWare></HardWare>
  </div>
</template>

<script>
import Banner from '@/components/production/Banner';
import Characteristic from '@/components/production/jetlinksedge/Characteristic';
import CoreFunction from '@/components/production/jetlinksedge/CoreFunction';
import HardWare from '@/components/production/jetlinksedge/HardWare';
export default {
  name: '',
  data() {
    return {
       label: [
        'JetLinks Edge网关是软硬件一体设备。主要应用在社区、工厂、学校、园区等项目现场，接入项目现场的硬件设备。两大核心功能：视频接入和物联网接入。',
        '项目现场的硬件设备接入边缘网关后，连接到云平台，云平台可以查看、编辑、操作……接入边缘网关的所有硬件。'
       ]
    }
  },
  components: {
    Banner,
    Characteristic,
    CoreFunction,
    HardWare
  },
  methods: {
    
  },
}
</script>
<style scoped>
  
</style>