<template>
  <jet-card class="hardware" title="硬件特色" :titleType="2">
    <table>
      <thead>
        <th>系统参数</th>
      </thead>
      <tbody v-for="item in parameterList" :key="item.key">
        <tr>
          <td class="item-name">
            <div>{{ item.name }}</div>
          </td>
          <td class="item-content" v-html="item.content"></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead>
        <th>内部接口</th>
      </thead>
      <tbody>
        <tr>
          <td class="item-name">
            <div>拓展接口</div>
          </td>
          <td class="item-content">
            1xM.2PCle3.0(扩展NVMeSSD)<br>1xSATA3.0(扩展2.5寸SATASSD/HDD)<br>1xM.2扩展5G(或1xMiniPCle扩展4GLTE)</td>
        </tr>
        <tr>
          <td class="img-td">
            <img src="@/assets/images/production/edge/hardware.png" alt="">
          </td>
        </tr>
      </tbody>
    </table>
  </jet-card>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      parameterList: [
        {
          key: 1,
          name: '以太网',
          content: '支持双千兆以太网(1000Mbps)<br/>其中LAN(PoE)网口支持POE+(802.3AT输出功率30W)供电 <br/>满足NVR、工业网关等多网口产品需求'
        }, {
          key: 2,
          name: '无限网络',
          content: '支持M.2扩展5GMiniPCle扩展4GLTE<br/>支持2.4G/5GHz双频WiFi，802.11a/b/g/n/ac/ax,WiFi6<br/>支持BT5.0'
        }, {
          key: 3,
          name: '显示',
          content: '1xHDMI2.0，支持4K@60fps输出'
        }, {
          key: 4,
          name: '音频',
          content: '1xHDMI音频输出<br/>1xPhone 耳机输出<br/>1xMic麦克风板载音频输入'
        }, {
          key: 5,
          name: '串口',
          content: '2xCANFD/CAN、2xRS232、2xRS485 '
        }, {
          key: 6,
          name: 'USB',
          content: '2xUSB3.0、2xUSB2.0'
        }, {
          key: 7,
          name: '按钮',
          content: '1xPower Button'
        }
      ],
    }
  },
  components: {

  },
  methods: {

  },
}
</script>
<style scoped lang="less">
table:first-child {
  margin-top: 3rem;
}

table {
  width: 100%;
  background-color: #fff;

  th,
  tr {
    display: block;
  }

  th {
    font-weight: 400;
    font-size: 1.3em;
    line-height: 2.6rem;
    height: 2.6rem;
    color: white;
    text-align: center;
    background-color: #C7D7F7;
  }

  tr {
    display: flex;
  }

  td {
    border: #eff3f6 1px solid;

    img {
      height: 32.222vh;
      margin: 10px;
    }
  }

  .img-td {
    text-align: center;
    width: 100%;
  }

  tbody {
    .item-name {
      display: flex;
      align-items: center;
      width: 11.458vw;
      padding-left: 1.7rem;
      line-height: 100%;
    }

    .item-content {
      padding: 1.5rem 0.5rem;
      flex: 1;
      line-height: 2rem;
    }
  }
}
</style>